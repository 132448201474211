.Ad {
  > img,
  > picture > img {
    display: block;
    height: 100%;
    width: 100%;
  }

  &[data-variant="banner"] {
    display: flex;
    justify-content: center;
    padding: calc($Content-gutter * 0.5) $Content-gutter;

    > img,
    > picture > img {
      max-height: 7.5rem;
    }
  }

  &[data-variant="landscape"] {
    display: block;
    height: 100%;
    position: relative;

    > img,
    > picture > img {
      left: 0;
      object-fit: contain;
      position: absolute;
      top: 0;
    }
  }
}
