.ListGroup {
  overflow: hidden;

  > ul {
    column-gap: calc($Content-gutter * 2);
    list-style: none;
    margin: calc($Content-gutter * -1 - $Separator-width) 0 0 0;
    padding: 0;
  }

  &-item {
    align-items: center;
    border-top: $Separator;
    break-inside: avoid;
    display: flex;
    padding-top: $Content-gutter;

    &:not(:last-child) {
      margin-bottom: $Content-gutter;
    }

    &-content {
      flex-grow: 1;
    }

    &-data {
      display: flex;
      flex-wrap: wrap;
      gap: calc($Content-gutter * 0.5);

      &-label {
        flex-grow: 1;
      }
    }

    &-icon {
      margin-right: calc($Content-gutter * 0.5);
    }

    &-information {
      font-size: $Small-fontSize;
    }

    $statuses: (
      error: (
        color: $color--error,
      ),
      info: (
        color: $color--info,
      ),
      success: (
        color: $color--success,
      ),
      warning: (
        color: $color--warning,
      ),
    );
    @each $status, $rules in $statuses {
      &[data-status="#{$status}"] & {
        &-data {
          &-value {
            color: map-get($rules, color);
          }
        }
      }
    }
  }

  &[data-column-count] {
    > ul {
      column-count: 1;
    }
  }

  @include for-desktop() {
    $columnCounts: 2, 3;
    @each $columnCount in $columnCounts {
      &[data-column-count="#{$columnCount}"] {
        > ul {
          column-count: $columnCount;
        }
      }
    }
  }
}
