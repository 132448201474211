.Overlay {
  background: rgba(43, 43, 43, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  &[data-state="closed"] {
    animation: OverlayClosed 125ms ease-in;

    @keyframes OverlayClosed {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  }

  &[data-state="open"] {
    animation: OverlayOpen 125ms ease-out;

    @keyframes OverlayOpen {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
}
